@import '../../marketplace.css';

.aboutUsListItem {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
  
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
  }
}

.aboutUsListItemTitle {
  flex-shrink: 0;
  min-width: 20%;
  margin-right: 10px;
  font-size: 18px;
  font-weight: var(--fontWeightBold);
}

.listImage {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  object-fit: contain;
}

.editBtnsWrap {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
}

.editButton, .deleteButton {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin-left: 10px;
}

.editButton {
  background-color: rgba(226, 230, 0, 0.76);
}

.deleteButton {
  background-color: rgba(230, 35, 0, 0.76);
  color: var(--matterColorLight)
}

.confirmDeleteWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmDeleteBtnsWrap {
  margin-top: 10px;
}

.confirmDeleteBtnsWrap > button {
  margin-right: 10px;
}

.confirmTitle {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

.deleteError {
  text-align: center;
  color: var(--failColor);
}
