@import '../../marketplace.css';

.layoutWrapperMain {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;
  min-height: calc(100vh - var(--topbarHeight));
  align-items: center;

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.addNewBtn {
  align-self: flex-end;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin-left: 10px;
  background-color: rgba(53, 189, 0, 0.76);
  color: var(--matterColorLight)
}
