@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 50px 0;
}

.becomingTutorWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  
  @media (--viewportLarge) {
    align-items: center;
    flex-direction: row;
  }
}


.becomingTutorItem {
  display: flex;
  flex-direction: column;
  
  @media (--viewportSmall) {
    align-items: center;
    flex-direction: row;
  }
}

.becomingTutorImg {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  object-fit: cover;
}

.createAccountBtn {
  @apply --marketplaceButtonFontStyles;
  display: block;
  width: 100%;
  padding: 20px 0;
  margin-top: 50px;
  border-radius: var(--borderRadiusButtons);

  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: center;
  
  &:hover {
    text-decoration: none;
    background: var(--marketplaceColorSecondary);
  }

  @media (--viewportSmall) {
    width: 300px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
  }
}