@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.spinner {
  justify-content: center;
}

.textWrapper {
  padding: 24px 0;
}

.buttonsWrapper {
  display: flex;
  margin: 24px 0;
}

.buttonsWrapper > button {
  margin-left: 10px;
}

.buttonsWrapper > button:first-child {
  margin-left: 0;
  margin-right: 10px;
}