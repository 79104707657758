@import '../../marketplace.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.features ul {
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.features ul li {
  display: block;

  @media (--viewportMedium) {
    display: flex;
  }
}

.features ul li > div {
  margin-left: 0;

  @media (--viewportMedium) {
    margin-left: 10px;
  }
}

.features ul li > span {
  width: 164px;
  margin-right: 10px;
}

.error {
  color: var(--failColor);
}

.customText {
  margin: 0 0 32px 0;
  @apply --marketplaceLightFontStyles;

  @media (--viewportLarge) {
    max-width: 85%;
    margin: 0;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 300px;
    margin-top: 100px;
  }
}

.quizesResult {
  width: 200px;
  min-height: auto;
  padding: 5px 10px;
  margin: 10px 0 50px;
  background-color: var(--successColorDark);

  @media (--viewportMedium) {
    margin: 10px 0 0;
  }
}
