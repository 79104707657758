@import '../../marketplace.css';

.form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 50px 0 0 0;

@media (--viewportSmall) {
  flex-direction: row;
}

@media (--viewportMedium) {
  width: 50%;
  margin: 0 0 15px 0;
}
}

.field {
  display: block;
  flex-direction: column;
  position: relative;

@media (--viewportSmall) {
  margin-right: 20px;
  flex-basis: calc(100% - 116px);
  max-width: calc(100% - 116px);
}
}

.input {
  padding: 5px 16px;
  min-height: 40px;
  background: var(--matterColorLight);
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  width: 100%;
  height: 64px;
  margin-top: 50px;

@media (--viewportSmall) {
  width: 116px;
  flex: 0 0 116px;
  min-height: 40px;
  max-height: 40px;
  margin-top: 0;
}
}
