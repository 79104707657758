@import '../../marketplace.css';

.root {

}

.filesList {
  margin: 0;
  padding: 20px;
  margin-bottom: 30px;
}

.filesItem {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field {
  margin-bottom: 30px;
}

.fieldSelect {
  margin-bottom: 30px;
}

.fieldSelect select {
  border-bottom-color: var(--successColor);
}

.fieldRequired label::after{
  content: ' *';
  color: var(--marketplaceColorSecondary);
}

.note,
.noteWithMargin {
  font-size: 12px;
  color: var(--matterColorAnti);
  /* margin-top: 0; */
  margin: 0;
}

.noteWithMargin {
  margin: 0 0 12px;
}

.fieldRequiredLabel::after{
  content: ' *';
  color: var(--marketplaceColorSecondary);
}
