@import '../../marketplace.css';

.heading {
    @apply --marketplaceModalTitleStyles;
    padding: 0 0 40px;
}

.formGroup {
    width: 100%;
    padding: 0 0 30px;
}

.field {
    margin: 0 0 24px;
}

.fieldFileUpload {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.input {
    border-bottom-color: #E77B20;
}

.labelFileUpload {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: gainsboro;
}

.inputFileUpload {
    border: none;
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.containerUploadFile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
}

.containerUploadFileButton {
    background-color: #f76363;
    border-radius: 5px;
    padding: 5px 10px;
    border: none;
    color: white;
}

.recaptchaMessage {
    margin: 0;
    padding: 30px 0 0;
    color: black;
    opacity: 0.65;
    text-align: center;
    font-size: 13px;

    @media (--viewportMedium) {
        padding: 30px 25px 0;
      }
}