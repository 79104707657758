@import '../../../marketplace.css';

.bonusesContentWrap {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}

.radioWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 30px;
}

.radioWrap > input {
  width: 20px;
  height: 20px;
}

.radioWrap > label {
  padding: 0;
  @apply --marketplaceH4FontStyles;
  font-weight: 500;
  margin: 0;
  padding: 0 15px 0 0;
}
