@import '../../marketplace.css';

.root {
  background: var(--matterColorLight);
}

.sectionTitle {
  text-align: center;
  margin-bottom: 40px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 250px;
  border-radius: 4px;
  object-fit: cover;

  @media (--viewportMedium) {
    height: 528px;
  }
}

.listImage {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  object-fit: contain;
}

.aboutUsListItem {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
  }
}

.imgContainer {
  position: relative;
  width: 100%;
  margin: 32px 0 40px;
}

.noImg {
  position: relative;
  margin: 32px 0 40px;
  width: 100%;
  height: 250px;
  background-color: var(--matterColorAnti);
}

.addNewBtn {
  align-self: flex-end;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin: 0 auto;
  background-color: rgba(53, 189, 0, 0.76);
  color: var(--matterColorLight);
  display: flex;
}

.changeBtn {
  align-self: flex-end;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin: 0 auto;
  background-color: rgba(226, 230, 0, 0.76);
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
}

.aboutUsListItemTitle {
  flex-shrink: 0;
  min-width: 20%;
  margin-right: 10px;
  font-size: 18px;
  font-weight: var(--fontWeightBold);
}

.aboutUsListItemText {
  @apply --marketplaceBodyFontStyles;
}

.createAccountBtn {
  @apply --marketplaceButtonFontStyles;
  display: block;
  width: 100%;
  margin: 40px auto;
  padding: 20px 0;
  border-radius: var(--borderRadiusButtons);

  @media (--viewportSmall) {
    width: 300px;
  }

  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: center;

  &:hover {
    text-decoration: none;
    background: var(--marketplaceColorSecondary);
  }
}

.errorMsq {
  text-align: center;
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
}

.loadingMsq {
  text-align: center;
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorAnti);
}
