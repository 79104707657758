@import '../../marketplace.css';

.wrapper {

}

.button {
  padding: 0 20px;
}

.textInProgress {
  color: var(--matterColorAnti)
}

.textDone {
  color: var(--successColor)
}