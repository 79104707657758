@import '../../marketplace.css';

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.coverImage {
  width: 100%;
  max-height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.pageTitle {
  text-align: center;
  margin-top: 0;
}

.editBtnsWrap {
  /* width: 100px; */
  display: flex;
  align-self: flex-end;
}

.editButton, .deleteButton {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin-left: 10px;
}

.editButton {
  background-color: rgba(226, 230, 0, 0.76);
}

.deleteButton {
  background-color: rgba(230, 35, 0, 0.76);
  color: var(--matterColorLight)
}

.confirmTitle {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

.confirmDeleteWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmDeleteBtnsWrap {
  margin-top: 10px;
}

.confirmDeleteBtnsWrap > button {
  margin-right: 10px;
}

.errorMesssage {
  text-align: center;
  color: var(--failColor);
}
