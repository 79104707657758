@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }
}

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 10px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 18px 36px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceBodyFontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceBodyFontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceBodyFontStyles;
  height: 100%;
  padding: 0 6px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding: 0 12px 0 12px;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationBadge {
  position: absolute;
  top: 0;
  right: -2px;
  width: 14px;
  min-width: 14px;
  height: 14px;
  padding: 0 0 0 1px;
  margin: 0;
  font-size: 10px;
  line-height: 13px;
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 6px;

  @media (--viewportLarge) {
    padding: 0 12px 0 12px;
    margin-right: 12px;
    width: auto;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;

  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.signupLinkInner {
  height: auto;
  padding: 24px 12px;
  @apply --marketplaceBodyFontStyles;
  font-weight: var(--fontWeightRegular);
  margin: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='3' viewBox='0 0 8 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 3L0.535898 -1.75695e-07L7.4641 4.29987e-07L4 3Z' fill='%23614983'/%3E%3C/svg%3E%0A");
  background-position: right;
}

.signupLinkMenu {
  padding: 12px;
  border: 1px solid var(--borderDefault);
  border-radius: 0;
}

.signupLinkMenuItem {
  @apply --marketplaceBodyFontStyles;
  color: var(--matterColor);

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.signup,
.login {
  @apply --marketplaceBodyFontStyles;
  @apply --TopbarDesktop_label;
  font-weight: var(--fontWeightRegular);
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceBodyFontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceBodyFontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--marketplaceColor);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--marketplaceColor);
    }
  }
}

.infoLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
  border-left: 1px solid var(--borderDefault);
  padding-left: 10px;

  @media(--viewportLargeWithPaddings) {
    padding-left: 50px;
  }
}

.list {
  display: flex;
  height: 100%;
}

.listItem {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  @media(--viewportLargeWithPaddings) {
    margin-right: 34px;
  }
}


.link {
  display: flex;
  height: 100%;
  padding: 26px 0;
  @apply --TopbarDesktop_linkHover;
  /* Font */
  @apply --marketplaceBodyFontStyles;
  line-height: 20px;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.activeLink {
  color: var(--marketplaceColor);
}

.userLinksBlock {
  display: flex;
  height: 100%;
}

.languages {
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='3' viewBox='0 0 8 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 3L0.535898 -1.75695e-07L7.4641 4.29987e-07L4 3Z' fill='%23614983'/%3E%3C/svg%3E%0A");
  background-position: right;
}

.langBtn {
  padding: 0 8px 0 25px;
  background: url('../../assets/language_uk.png') no-repeat left;
  text-transform: uppercase;
}

.langBtnOption {
  padding: 5px 20px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorGrayBackground);
  }
}

.en {
  background: url('../../assets/language_uk.png') no-repeat left;
}

.de {
  background: url('../../assets/language_de.png') no-repeat left;
}