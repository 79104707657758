@import '../../marketplace.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

:root {
  --button: {
    min-width: 140px;
    min-height: auto;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
  }
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 2px 0;
  display: flex;
  align-items: center;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 8px 0;
  }
}

.itemCheckbox {
  width: 130px;
  margin-right: 20px;
}

.price,
.duration {
  margin-left: 10px;
}

.buttonToQuiz {
  @apply --button;

  background-color: var(--matterColorAnti);
}

.buttonToQuizNoPass {
  @apply --button;

  background-color: var(--failColor);
}

.buttonToQuizPending {
  @apply --button;

  background-color: var(--successColorDark);
}
