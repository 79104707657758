@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.email {
  padding-bottom: 24px;

  @media (--viewportMedium) {
    padding-bottom: 32px;
  }
}
.name {
  display: flex;
  display: -webkit-flex;
  display: -webkit-box;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  padding: 0 0 24px;

  @media (--viewportMedium) {
    padding: 0 0 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  padding-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperTextFirst {
  margin-top: 0;
  padding-bottom: 8px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.bottomWrapperText {
  margin-top: 0;
  padding-bottom: 8px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.signUpSelect {
  padding-bottom: 32px;
}
