@import '../../marketplace.css';

.root {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;

  &:hover {
    & .priceLabel,
    & .caret {
      cursor: pointer;
    }
  }
}

.priceLabel {
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;

  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  letter-spacing: 0.1px;

  /* Dimensions */
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:hover {
    & .activeMapIcon {
      fill: var(--marketplaceColorSecondary);
    }
  }
}


.priceLabelActive .activeMapIcon {
  fill: var(--marketplaceColorSecondary);
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: var(--boxShadowPopupLight);
}

.caret {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  transition: var(--transitionStyleButton);

  /* Caret should have same bg-color and border as label */
  background-color: var(--matterColorLight);
  border-right-style: solid;
  border-right-color: var(--matterColorNegative);
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--matterColorNegative);
  border-bottom-width: 1px;
}

.caretActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}
