@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 50px 0;
}

.reviewBlock {
  overflow: hidden;
}

.reviewRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -36px;
}

.reviewCol {
  padding: 0 36px;
  margin-bottom: 20px;
  border-right: 1px solid var(--borderDefault);
  width: 100%;

  &:last-child {
    border: none;
  }

  @media (--viewportLarge) {
    width: calc(100%/3);
    margin-bottom: 0;
  }

}

.reviewItem {
  display: block;
}

.reviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.reviewInitials {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 12px;
  text-align: center;
  border-radius: var(--borderRadiusButtons);
  background-color: var(--marketplaceColorSecondary);
  color: var(--matterColorLight);
}

.reviewAuthor {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reviewName {
  @apply --marketplaceBodyFontStyles;
  margin: 0;
}

.reviewText {
  @apply --marketplaceLightFontStyles;
  font-style: italic;
}

.reviewImgContainer {
  width: 40px;
  height: 40px;
  border-radius: var(--borderRadiusButtons);
  position: relative;
  margin-right: 12px;
}

.reviewAvatar {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}