@import '../../../marketplace.css';

.title {
    text-align: center;
}

.agreementWrap {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    padding: 5px 15px;
    width: 100%;
    min-width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 19px 5px rgb(0 0 0 / 25%);
    justify-content: space-between;
  
    /* @media (min-width: 400px) {
      width: 500px;
    } */
  
    @media (--viewportMedium) {
      margin-top: 25px;
    }
}

.agreementInfoContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 10px 0 0;
}

.topRow {
    display: flex;
    justify-content: space-between;
    padding: 0 0 8px;
}
  
/* .title {
    @apply --marketplaceH4FontStyles;
    margin: 0;
    color: var(--matterColorAnti);
} */

.editBtnsWrap {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
}

.editButton,
.deleteButton {
  padding: 2px 8px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin: 0 0 5px;
}

.editButton {
  background-color: rgba(226, 230, 0, 0.76);
}

.deleteButton {
  background-color: rgba(230, 35, 0, 0.76);
  color: var(--matterColorLight);
}

.agreementsList {
    padding: 0 0 20px;
}

.createRow {
    margin-bottom: 40px;
}