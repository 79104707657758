@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.certificate {
  @apply --EditListingDescriptionForm_formMargins;
}

.inputsWrapper {
  margin-bottom: 32px;
}

.lessonTypeLabel {
  font-weight: var(--fontWeightSemiBold);
}

.sessionTypeLabel {
  font-weight: var(--fontWeightSemiBold);
}

.customText {
  margin: 0 0 32px 0;
  @apply --marketplaceLightFontStyles;

  @media (--viewportLarge) {
    max-width: 85%;
    margin: 0;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 300px;
    margin-top: 100px;
  }
}

.hideOptions {
  display: none;
}
