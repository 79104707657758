@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 50px 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  margin-bottom: 40px;
  
  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    width: 324px;
    margin-bottom: 0;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.stepText {
  @apply --marketplaceLightFontStyles;
  margin: 0;
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.icons {
  fill: none;
}