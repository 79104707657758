@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  background-color: blanchedalmond;
  padding: 10px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  height: 100%;
}

.allInfoContainer {
  display: inline-flex;
  padding: 0 0 50px;
  width: 100%;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  border-radius: var(--borderRadiusListingCartImage);
  width: 40%;
  position: relative;
  margin: 0 5px 0 0;
}

.info {
  padding: 0;
  background-color: var(--matterColorBright);
  width: 60%;
  border-radius: 5px;
}

.mainInfo {
  display: flex;
  width: 100%;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tutorInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
}

.tutorName {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  overflow: hidden;
  word-break: break-word;
  margin: 0;
  padding: 5px 0 12px;
  text-align: center;
  width: 100%;
}

.tutorSubjectsList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin: 6px 0 0;
}

.tutorSubjectsItem {
  margin: 0 6px 0 0;

  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  text-align: center;
}

.subjectContainer {
  padding: 0 0 10px;
  width: 100%;
}

.row {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  text-align: center;
  width: 100%;
  margin: 0;
  line-height: 18px;
  padding: 0 0 10px;
}

.sessionType {
  margin: 0;
  text-align: center;
  width: 100%;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5px;
}

.buttonViewProfile {
  text-align: center;
  padding: 5px 15px;
  background-color: #984306;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
    color: white;
  }
}

.buttonContact,
.buttonSave {
  width: 100%;
  min-height: 30px;
  margin: 0 0 10px;
  background-color: var(--attentionColor);
  border-radius: 5px;
  box-shadow: 0px 5px 5px 0px rgb(121 121 121 / 81%);

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 5px 5px 0px rgb(121 121 121 / 91%);
  }
}

.buttonDelete {
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColor);
  }
  &:focus {
    background-color: var(--marketplaceColor);
  }
}

.reviewStars {
  display: block;
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.listingImage {
  position: relative;
  height: 150px;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  margin: 0 0 10px;
}
