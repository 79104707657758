@import '../../marketplace.css';

div.root {
  position: relative;
  display: block;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.label,
.labelSelected {
  @apply --marketplaceH3FontStyles;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
  border: none;
}

.label {
  color: var(--matterColorDark);
}

.labelSelected {
  color: var(--marketplaceColor);
}


.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  display: block;
  padding-left: 20px;

  & [class*="buttonsWrapper"] {
    padding: 0;
    justify-content: flex-end;

    & [class*="clearButton"] {
      position: absolute;
      right: 0;
      top: 38px;
    }

    & [class*="submitButton"],
    & [class*="cancelButton"] {
      display: none;
    }
  }
}










