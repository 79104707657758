@import '../../marketplace.css';

.layoutWrapperMain {

  width: calc(100% - 48px);
  max-width: 1400px;
  margin: 24px auto;
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.topbar {
  z-index: 1;
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.wizard,
.placeholderWhileLoading {
  flex-grow: 1;
}

.tabTitle {
  @apply --marketplaceH4FontStyles;
  margin: 0;
}
