@import '../../../marketplace.css';

.container {
  padding: 30px 0 0;
}

.conponentTitle {
  @apply --marketplaceH3FontStyles;
  margin: 0;
  text-align: center;
  padding: 0 0 45px;
}

.subjectInputInitial {
  padding: 0 0 40px;
}

.noSubjectsContainer {
  text-align: center;
  padding: 0 0 40px;
}

.noSubjectsTitle {
  @apply --marketplaceH3FontStyles;
  margin: 0;
  font-weight: 400;
}

.subjectInputInitial > label,
.subjectInput > label {
  padding: 0;
  @apply --marketplaceH4FontStyles;
  font-weight: 500;
  margin: 0;
  padding: 12px 0 8px 0;
  color: var(--matterColorAnti);
}

.formContainer {
  padding: 20px 0;
  max-width: 700px;
  margin: 0 auto;
}

.submitButton {
  margin: 70px 0 0;
}

.errorMessage {
  @apply --marketplaceH4FontStyles;
  text-align: center;
  color: var(--failColor);
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.addNewBtn {
  align-self: flex-end;
  padding: 8px 15px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin: 0;
  background-color: rgba(53, 189, 0, 0.76);
  color: var(--matterColorLight);
  font-size: 18px;
}

.subjectsContainer {
  padding: 0 0 20px;
}

.subjectWrap {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  padding: 5px 15px;
  width: 100%;
  min-width: 300px;
  border-radius: 5px;
  box-shadow: 0 0 19px 5px rgb(0 0 0 / 25%);
  justify-content: space-between;

  /* @media (min-width: 400px) {
    width: 500px;
  } */

  @media (--viewportMedium) {
    margin-top: 25px;
  }
}

.editBtnsWrap {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.editButton,
.deleteButton {
  padding: 2px 8px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin: 0 0 5px;
}

.editButton {
  background-color: rgba(226, 230, 0, 0.76);
}

.deleteButton {
  background-color: rgba(230, 35, 0, 0.76);
  color: var(--matterColorLight);
}

.subjectInfoContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 10px 0 0;
}

.topRow {
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px;
}

.title {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
}

.bottomRow {
  margin: 0;
}

.confirmDeleteWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmDeleteBtnsWrap {
  margin-top: 10px;
}

.confirmDeleteBtnsWrap > button {
  margin-right: 10px;
}

.deleteError {
  text-align: center;
  color: var(--failColor);
}
