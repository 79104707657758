@import '../../marketplace.css';

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalTitle {
  @apply --marketplaceH3FontStyles;
}

.savePostBtn {
  display: block;
  margin: 20px auto;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  padding: 6px 20px;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border: none;
}

.savePostBtnDisabled {
  display: block;
  margin: 20px auto;
  border-radius: 5px;
  font-size: 18px;
  padding: 6px 20px;
  background-color: var(--matterColorAnti);
  color: var(--matterColorLight);
  border: none;
}

.label {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColorGrayText);
  margin: 0;
  padding: 25px 0 15px;
}

.image {
  width: 50px;
  height: 50px;
  margin: 0 0 15px;
}

.prevImageContainer {
  display: flex;
  flex-direction: column;
}

.changeImgButton {
  width: 130px;
  border-radius: 5px;
  padding: 5px 0;
}

.editError {
  text-align: center;
  color: var(--failColor);
}
