@import '../../../marketplace.css';


.transactionTableTitle {
    text-align: center;
}

.transactionTable {
    margin: 0 auto;

  & tr {

  }

  & th,
  & td {
    padding: 20px;
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

.dateElement {
  border-radius: 5px;
  margin: 0 0 5px;
  background-color: palegoldenrod;
  padding: 2px 5px;
  border: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  font-weight: bold;
}

.dates {
  margin-bottom: 10px;
}
