@import '../../marketplace.css';

.content {
@media (--viewportMedium) {
  margin: 32px auto 0 auto;
  max-width: 564px;
}

@media (--viewportLarge) {
  margin: 0;
}
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  hyphens: auto;
  margin-top: 8px;
  margin-bottom: 19px;

@media (--viewportMedium) {
  margin-bottom: 47px;
}
}

.spinner {
  display: flex;
  justify-content: center;
}

.hasApp {
  @media(--viewportSmall){
    display: flex;
  }
}

.hasApp > :first-child {
  margin-right: 0;
  margin-bottom: 20px;

  @media(--viewportSmall){
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.hasApp > :last-child {
  margin-left: 0;

  @media(--viewportSmall){
    margin-left: 10px;
  }
}

.statusWrapper {
  margin-bottom: 40px;
  
  font-size: 24px;
  font-weight: 700;

  @media(--viewportSmall){
    display: flex;
  }
}

.statusTitle {
  margin-right: 24px;
  display: inline-block;
  margin-bottom: 24px;

  @media(--viewportSmall){
    margin-bottom: 0;
  }
}

.status {
  display: inline-block;
}

.status.pending  {
  color: var(--attentionColor)
}

.status.approved  {
  color: var(--successColorDark)
}

.error {
  color: var(--failColor);
}