@import '../../marketplace.css';

.blogItemWrap {
  width: 320px;
  overflow: hidden;
  margin: 0 5px;
  padding: 0 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 400px) {
    margin: 0;
    width: 320px;
  }

  /* @media (min-width: 1200px) {
    width: calc((100% - 30px) / 3);
  } */
}

.blogItemTitle {
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0;
}

.blogItemText {
  margin-bottom: 10px;
}

.imageContainer {
  position: relative;
  width: 320px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
