@import '../../marketplace.css';

.announcementWrap {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 20px 15px;
  width: 100%;
  min-width: 300px;
  max-width: 850px;
  border-radius: 5px;
  box-shadow: 0 0 19px 5px rgb(0 0 0 / 25%);

  /* @media (min-width: 400px) {
    width: 500px;
  } */

  @media (--viewportMedium) {
    margin-top: 25px;
  }
}

.editBtnsWrap {
  display: flex;
  align-self: flex-end;
}

.editButton, .deleteButton {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin-left: 10px;
}

.editButton {
  background-color: rgba(226, 230, 0, 0.76);
}

.deleteButton {
  background-color: rgba(230, 35, 0, 0.76);
  color: var(--matterColorLight)
}

.topRow {
  display: flex;
  justify-content: space-between;
}

.title {
  @apply --marketplaceH3FontStyles;

  @media (--viewportMedium) {
    @apply --marketplaceH3FontStyles;
    font-size: 36px;
  }
}

.bottomRow {
  margin: 0;
}

.confirmDeleteWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmDeleteBtnsWrap {
  margin-top: 10px;
}

.confirmDeleteBtnsWrap > button {
  margin-right: 10px;
}

.confirmTitle {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

.date {
  display: flex;
  justify-content: flex-end;
}

.deleteError {
  text-align: center;
  color: var(--failColor);
}
