@import '../../marketplace.css';

.root {
  display: flex;
  justify-content: space-between;
  background-color: var(--matterColorBright);
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  & > *:not(:last-child) {
    margin: 0 10px 10px 0;
    height: auto;
  }
}

.searchResultSummary {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  line-height: 20px;
  margin-top: 9px;
  margin-bottom: 11px;

  /* parent uses flexbox: this defines minimum width for text "6 results" */
  flex-basis: 55px;
  flex-shrink: 0;
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}