@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 50px 0;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  padding: 0;
  margin-bottom: 50px;
  
  @media (--viewportMedium) {
    width: calc( 100% / 3);
    padding: 50px;
    margin-bottom: 50px;
  }
}

.stepLink:hover {
  text-decoration: none;
}

.stepTitle {
  text-align: center;
  color: var(--matterColor);
}

.stepImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.createAccountBtn {
  @apply --marketplaceButtonFontStyles;
  display: block;
  width: 100%;
  margin: 40px auto 0;
  padding: 20px 0;
  border-radius: var(--borderRadiusButtons);
  
  @media (--viewportSmall) {
    width: 300px;
  }

  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: center;
  
  &:hover {
    text-decoration: none;
    background: var(--marketplaceColorSecondary);
  }
}