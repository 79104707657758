@import '../../marketplace.css';

.root {
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
  }
}

.menuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: var(--borderRadiusSearchFilterButton);

  &:focus {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    outline: none;
    text-decoration: none;
  }
}

.menuLabelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: var(--borderRadiusSearchFilterButton);
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorSecondary);
    background-color: var(--marketplaceColorSecondary);
  }
}

.menuContent {
  left: 0;
  right: auto;
  margin-top: 7px;
  padding-top: 13px;
  min-width: 300px;

  border-radius: var(--borderRadiusSearchFilterButton);
  border: 1px solid var(--borderDefault);
  box-shadow: none;
}

/* left animated "custom radio button" like hover element */
.menuItemCustomRadioButton,
.menuItemCustomRadioButtonSelected {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  margin-right: 10px;

  border: 1px solid var(--borderCustomRadioButton);
  border-radius: var(--borderRadiusButtons);
}

/* left static custom radio button for selected element */
.menuItemCustomRadioButtonSelected {

    border: 1px solid var(--marketplaceColor);

    &:after {
    display: block;
    width: 10px;
    height: 10px;
    content: "";
    background-color: var(--marketplaceColor);
    border-radius: var(--borderRadiusButtons);
  }
}

.menuItem {
  display: flex;
  align-items: center;

  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  white-space: nowrap;

  cursor: pointer;

  &:hover .menuItemCustomRadioButton {
    border: 1px solid var(--marketplaceColor);
    &:after {
      display: block;
      width: 10px;
      height: 10px;
      content: "";
      background-color: var(--marketplaceColor);
      border-radius: var(--borderRadiusButtons);
    }
  }
}

.clearMenuItem {
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
 