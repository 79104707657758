@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.imgContainer {
  position: relative;
  width: 100%;
  margin: 32px 0 40px;
}

.noImg {
  position: relative;
  margin: 32px 0 40px;
  width: 100%;
  height: 250px;
  background-color: var(--matterColorAnti);
}

.changeBtn {
  align-self: flex-end;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin: 0 auto;
  background-color: rgba(226, 230, 0, 0.76);
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}

.addNewBtn {
  align-self: flex-end;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  @apply --marketplaceH5FontStyles;
  cursor: pointer;
  margin: 0 auto;
  background-color: rgba(53, 189, 0, 0.76);
  color: var(--matterColorLight);
  display: flex;
}

.errorMsqFaq {
  text-align: center;
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
}

.loadingMsqFaq {
  text-align: center;
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorAnti);
}