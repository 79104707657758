@import '../../marketplace.css';

:root {


  --SubscribeMessage_animation: {
    animation-name: animationTitle;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    bottom: 0;
  }
  10% {
    visibility: visible;
    opacity: 1;
    bottom: 20px;
  }
  100% {
    visibility: hidden;
    bottom: 20px;
  }
}

.title {
  @apply --marketplaceH2FontStyles;
}

.subscribeBlock {
  position: relative;
  display: flex;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
  }
}



.contactUsBtn {
  @apply --marketplaceButtonFontStyles;
  display: block;
  width: 100%;
  padding: 20px 0;
  margin-top: 50px;
  border-radius: var(--borderRadiusButtons);

  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: center;

  &:hover {
    text-decoration: none;
    background: var(--marketplaceColorSecondary);
    cursor: pointer;
  }

  @media (--viewportSmall) {
    width: 300px;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.subscribeFormText {
  width: 100%;
  padding-right: 10px;

  @media (--viewportMedium) {
    width: 40%;
  }
}

.subscribeMessageHolder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -115%;
  display: flex;
  justify-content: center;

@media (--viewportSmall) {
  bottom: -64%;
}

@media (--viewportMedium) {
  bottom: -110%;
}

@media (--viewportExtraSmall) {
  bottom: -34%;
}
}

.subscribeMessageItem {
  display: block;
  padding: 6px 20px;
  text-align: center;
  color: var(--matterColorLight);
  border-radius: var(--borderRadiusButtons);
  font-size: 16px;
  @apply --SubscribeMessage_animation;
}

.subscribeMessageError {
  background: var(--failColor);
}

.subscribeMessageSuccess {
  background: var(--successColor);
}
