@import '../../marketplace.css';

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalTitle {
  @apply --marketplaceH3FontStyles;
}

.label {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColorGrayText);
  margin: 0;
  padding: 25px 0 15px;
}

.savePostBtn {
  display: block;
  margin: 20px auto;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  padding: 6px 20px;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border: none;
}

.editError {
  text-align: center;
  color: var(--failColor);
}
