@import '../../marketplace.css';

.modalButtons {
  margin-top: 40px;
  display: flex;
}

.modalButton {
  width: 50%;
}

.modalButton:nth-child(odd) {
  padding-right: 10px;
}

.modalButton:nth-child(even) {
  padding-left: 10px;
}

.cancelButton {
  background-color: var(--matterColorLight);
  color: var(--matterColor)
}
