@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  @apply --marketplaceH2FontStyles;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  /* min-height: 60vh; */
  /* height: 300px%; */

  z-index: 100;

  padding-left: 0;
  padding-right: 0;

  background-color: var(--matterColorLight);
}

.textContainer {
  display: flex;
  align-items: center;
  padding: 30px;
  flex-direction: column;
}

.modalTitle {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

.credContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
}

.modalLabel {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorAnti);
  text-align: center
}

.modalText {
  @apply --marketplaceH4FontStyles;
  text-align: center
}

.modalLink {
   @apply --marketplaceH4FontStyles;
}

.modalError {
  @apply --marketplaceH3FontStyles;
  color: var(--failColor);
  text-align: center;
}