@import '../../../marketplace.css';


.transactionTableTitle {
    text-align: center;
}

.transactionTable {
    margin: 0 auto;

  & tr {

  }

  & th,
  & td {
    padding: 20px;
  }
}

