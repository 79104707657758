@import '../../marketplace.css';

.form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 50px 0 0 0;
  padding: 0 24px;

  @media (--viewportSmall) {
    flex-direction: row;
  }

  @media (--viewportMedium) {
    margin: 50px 0 15px 0;
  }

  @media (--viewportLarge) {
    padding: 0;
  }
}

.field {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewportSmall) {
    margin-right: 20px;
    flex-basis: calc(100% - 116px);
    max-width: calc(100% - 116px);
  }
}

.input {
  padding: 5px 16px;
  min-height: 40px;
  background: var(--matterColorLight);
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-size: 16px;
  margin: 0;
  width: 100%;
  height: 64px;
  margin-top: 50px;

  @media (--viewportSmall) {
    width: 116px;
    flex: 0 0 116px;
    min-height: 40px;
    max-height: 40px;
    margin-top: 0;
  }
}

.fieldFileUpload {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
}

.labelFileUpload {
  padding: 11px 18px;
  border-radius: 30px;
  background-color: var(--matterColorNegative);
  cursor: pointer;
  transition: all ease-in-out 0.1s;

  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }
}

.inputFileUpload {
  border: none;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.containerUploadList {
  width: 100%;
  margin-top: 40px;
}

.containerUploadFile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.containerUploadFileName {
  max-width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  word-break: break-word;
}

.containerUploadFileButton {
  max-height: 22px;
  background-color: var(--marketplaceColor);
  border-radius: 5px;
  padding: 0 6px 4px 6px;
  border: none;
  color: var(--matterColorLight);
  cursor: pointer;
  font-size: 24px;

  &:hover,
  &:focus {
    background: var(--marketplaceColorSecondary);
  }
}
