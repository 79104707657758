@import '../../marketplace.css';


.transactionTableTitle {
    text-align: center;
    margin: 20px;
    font-size: 20px;
    cursor: pointer;
}

.transactionTable {
  margin: 20px 0;
  width: 100%;

  & > * {
    width: 100%;
  }

  & tr {

  }

  & th,
  & td {
    padding: 20px 10px;
  }
}

.errorTd {
  color: red;
  font-size: 12px;
}


.inputTd {
  max-width: 172px;
}

.cursorButton {
  cursor: pointer;
}