@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;
  margin: 0 0 50px 0;
}

.subTitle {
  @apply --marketplaceH3FontStyles;
  margin: 0;
}

.onlineTeachingItem {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin: 100px 0 0;
  
  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
  }

  &:first-child,
  &:nth-child(3) {
    flex-direction: column-reverse;

    @media (--viewportMedium) {
      flex-direction: row;
    }
  }

  &:last-child {
    flex-direction: column;
    @media (--viewportLarge) {
      flex-direction: row;
    }
  }
}

.onlineTeachingList {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.onlineTeachingListItem {
  font-size: 18px;
  margin-bottom: 20px;
}

.onlineTeachingListItemTitle {
  display: flex;
  font-weight: bold;
  margin-bottom: 10px;
  
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.91667L4.66667 8L11 1' stroke='%23E77B20' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: left center;
  }
}

.onlineTeachingImg {
  width: 200px;
  height: 200px;
  margin-bottom: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
  
  @media (--viewportLarge) {
    width: 400px;
    height: 400px;
    margin-bottom: 0;
  }
}

.onlineTeachingImgSmall {
  width: 200px;
  height: 200px;
  margin-right: 20px;
}

.browseBtn {
  @apply --marketplaceButtonFontStyles;
  display: block;
  width: 100%;
  padding: 20px 0;
  border-radius: var(--borderRadiusButtons);
  margin-top: 50px;

  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: center;
  
  &:hover {
    text-decoration: none;
    background: var(--marketplaceColorSecondary);
  }

  @media (--viewportSmall) {
    width: 300px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.findTtutorText {
  margin: 0;

}